<template>
  <ion-page>
    <ion-grid fixed>
      <ion-row
        class="ion-justify-content-center ion-align-items-center ion-margin-top"
      >
        <ChangePassword
          is-forget-password
          v-model:success="successPassword"
          :error="errorPassword"
          @submit="submitPassword"
          @cancel="$router.go('/')"
        />
      </ion-row>
    </ion-grid>
  </ion-page>
</template>

<script>
import { mapActions } from "vuex";
import { IonPage, IonRow, IonGrid, alertController } from "@ionic/vue";
import ChangePassword from "@/components/ChangePassword";

export default {
  components: { ChangePassword, IonPage, IonRow, IonGrid },
  data: () => ({
    token: "",
    errorPassword: "",
    successPassword: false
  }),

  mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      this.loadRecovery();
    }
  },

  methods: {
    ...mapActions(["checkRecoveryToken", "changeForgetPassword"]),

    async showDialog(error = false) {
      const alert = await alertController.create({
        header: error ? `Error` : `Contraseña Actualizada`,
        message: error
          ? `Su token es inválido o ha expirado. Puede solicitar uno nuevo`
          : `Presione Aceptar para iniciar sesión con la nueva contraseña`,
        buttons: [
          {
            text: "Aceptar",
            handler: () => this.$router.push("/login")
          }
        ]
      });
      return alert.present();
    },

    async loadRecovery() {
      const { error } = await this.checkRecoveryToken(this.token);
      if (error) {
        this.showDialog(true);
      }
    },

    async submitPassword({ newPassword }) {
      this.errorPassword = "";
      const { error } = await this.changeForgetPassword({
        password: newPassword,
        token: this.token
      });
      if (error) {
        this.errorPassword = error;
      } else {
        this.successPassword = true;
        this.showDialog();
      }
    }
  }
};
</script>
